import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import React, { FC } from 'react'

import Head from '../../../layouts/Head'
import Layout from '../../global/Layout'
import ProductsContainer from './ProductsContainer'

export const ProductsPage: FC = () => {
  return (
    <>
      <Head title="Products" />
      <GlobalStyle includeTypography />
      <Layout>
        <ProductsContainer pageContext={{}} />
      </Layout>
    </>
  )
}
